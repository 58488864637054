import {
  ConfirmDialog,
  confirmDialog,
  ConfirmDialogProps,
} from 'primereact/confirmdialog';
import React, { createContext, useContext } from 'react';

interface ConfirmContextValue {
  showConfirm: (props: ConfirmDialogProps) => void;
}

const ConfirmContext = createContext<ConfirmContextValue | undefined>(
  undefined
);

export const ConfirmProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const showConfirm = (props: ConfirmDialogProps) => {
    confirmDialog(props);
  };

  return (
    <ConfirmContext.Provider value={{ showConfirm }}>
      {children}
      <ConfirmDialog className="w-full max-w-40rem" />
    </ConfirmContext.Provider>
  );
};

export const useConfirm = (): ConfirmContextValue => {
  const context = useContext(ConfirmContext);
  if (!context) {
    throw new Error('useConfirm must be used within a ConfirmProvider');
  }
  return context;
};
