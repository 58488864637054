import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from '@reduxjs/toolkit/query';

import { RootState } from '../../store/site-store';

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.NEXT_PUBLIC_API_URL,
  prepareHeaders: (headers, { getState }) => {
    const siteName = (getState() as RootState).siteState.siteName;

    const tokenKey = siteName ? `${siteName}-accessToken` : 'accessToken';
    const accessToken = localStorage.getItem(tokenKey);

    if (accessToken) {
      headers.set('Authorization', `Bearer ${accessToken}`);
    }
  },
});

export const baseQueryWithAuth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError,
  Record<string, unknown>,
  FetchBaseQueryMeta
> = async (args, api, extraOptions) => {
  const result = await baseQuery(
    {
      ...(args as FetchArgs),
    },
    api,
    extraOptions
  );

  //FIXME: Implement refresh token flow
  //// Check if the token is expired
  //if (result.error && 'status' in result.error && result.error.status === 401) {
  //  // Attempt to refresh the token
  //  const refreshResult = await baseQuery(
  //    {
  //      url: '/auth/refresh',
  //      method: 'POST',
  //      body: JSON.stringify({
  //        refreshToken: localStorage.getItem('refreshToken'),
  //      }),
  //    },
  //    api,
  //    extraOptions
  //  );

  //  if (refreshResult.data && typeof refreshResult.data === 'object') {
  //    const newTokens = refreshResult.data as AuthTokens;
  //    // Store the new tokens
  //    localStorage.setItem('accessToken', newTokens.accessToken);
  //    localStorage.setItem('refreshToken', newTokens.refreshToken);

  //    // Retry the original query with the new token
  //    result = await baseQuery(
  //      {
  //        ...(args as FetchArgs),
  //        headers: {
  //          ...(extraOptions?.headers || {}),
  //          Authorization: `Bearer ${newTokens.accessToken}`,
  //        },
  //      },
  //      api,
  //      extraOptions
  //    );
  //  } else {
  //    // If refresh also fails, handle according to your app's logic (e.g., logout)
  //    localStorage.removeItem('accessToken');
  //    localStorage.removeItem('refreshToken');

  //    // Implement additional logic such as redirecting to a login page or cleanup
  //    const referrer = window.location.pathname;
  //    window.location.href = `/en/auth/sign-in?referrer=${referrer}`;
  //  }
  //}

  return result;
};
