'use client';
import { BadgePt } from '@villastay/frontend/theme/Badge';
import { CalendarPt } from '@villastay/frontend/theme/Calendar';
import { DataTablePt } from '@villastay/frontend/theme/DataTable';
import { DropdownPt } from '@villastay/frontend/theme/Dropdown';
import { PaginatorPt } from '@villastay/frontend/theme/Paginator';
import { PanelPt } from '@villastay/frontend/theme/Panel';
import { SelectButtonPt } from '@villastay/frontend/theme/SelectButton';
import { SidebarPt } from '@villastay/frontend/theme/Sidebar';
import { TabPanelPt, TabViewPt } from '@villastay/frontend/theme/TabView';
import { PrimeReactProvider } from 'primereact/api';
import { DataTablePassThroughOptions } from 'primereact/datatable';
import { DropdownPassThroughOptions } from 'primereact/dropdown';
import Tailwind from 'primereact/passthrough/tailwind';
import { SidebarPassThroughOptions } from 'primereact/sidebar';
import { twMerge } from 'tailwind-merge';

import { ButtonPt } from '../../theme/Button';
import { InputTextPt } from '../../theme/InputText';
import { ConfirmProvider } from '../providers/confirm.provider';
import { ToastProvider } from '../providers/toast.provider';

interface VillaPrimeReactProviderProps {
  children: React.ReactNode;
}

/**
 *PrimeReact provider, with custom tailwind classes
 * @param root0 VillaPrimeReactProviderProps
 * @param root0.children React children
 * @returns React children
 */
export default function VillaPrimeReactProvider({
  children,
}: VillaPrimeReactProviderProps) {
  return (
    <PrimeReactProvider
      value={{
        unstyled: true,
        pt: {
          ...Tailwind,
          button: ButtonPt,
          inputtext: InputTextPt,
          paginator: PaginatorPt,
          selectbutton: SelectButtonPt,
          calendar: CalendarPt,
          sidebar: SidebarPt as unknown as SidebarPassThroughOptions,
          dropdown: DropdownPt as unknown as DropdownPassThroughOptions,
          panel: PanelPt,
          datatable: DataTablePt as unknown as DataTablePassThroughOptions,
          badge: BadgePt,
          tabview: TabViewPt,
          tabpanel: TabPanelPt,
        },
        ptOptions: {
          mergeSections: true,
          mergeProps: true,
          classNameMergeFunction: (className1: string, className2: string) => {
            return twMerge(className1, className2);
          },
        },
      }}
    >
      <ConfirmProvider>
        <ToastProvider>{children}</ToastProvider>
      </ConfirmProvider>
    </PrimeReactProvider>
  );
}
