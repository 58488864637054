import { Toast, ToastMessage } from 'primereact/toast';
import React, { createContext, useContext, useRef } from 'react';

interface ToastContextValue {
  showToast: (toastMessage: ToastMessage) => void;
}

const ToastContext = createContext<ToastContextValue | undefined>(undefined);

export const ToastProvider = ({ children }: { children: React.ReactNode }) => {
  const toast = useRef<Toast>(null);

  const showToast = (toastMessage: ToastMessage) => {
    toast.current?.show(toastMessage);
  };

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
      <Toast ref={toast} />
    </ToastContext.Provider>
  );
};

export const useToast = (): ToastContextValue => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return context;
};
