import { combineReducers, configureStore, Reducer } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import { villaStayApi } from '../services/api/villastay.api';

export type RootState = ReturnType<typeof appReducer>;
export type AppDispatch = typeof store.dispatch;

const appReducer = combineReducers({
  [villaStayApi.reducerPath]: villaStayApi.reducer,
});

const rootReducer: Reducer<RootState> = (state, action) => {
  return appReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(villaStayApi.middleware),
  devTools: process.env.NODE_ENV !== 'production',
});

// For refetchOnFocus, refetchOnReconnect
setupListeners(store.dispatch);

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
