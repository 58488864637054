import { classNames } from 'primereact/utils';

export const InputTextPt = {
  root: ({ props, context }: { props: any; context: any }) => ({
    className: classNames(
      'prime-inputtext',
      'm-0',
      'font-sans text-gray-600 dark:text-white/80 bg-white dark:bg-gray-900 border border-gray-300 dark:border-primary-900/40 transition-colors duration-200 appearance-none rounded-full',
      {
        'hover:border-primary-500 focus:outline-none focus:outline-offset-0':
          !context.disabled,
        'opacity-60 select-none pointer-events-none cursor-default':
          context.disabled,
      },
      {
        'text-lg px-4 py-4': props.size == 'large',
        'text-xs px-2 py-2': props.size == 'small',
        'p-3 text-base': props.size == null,
      }
    ),
  }),
};
