'use client';
import React from 'react';
import { Provider } from 'react-redux';

import { store } from './store';

/**
 * Store provider component
 * @param children - React children
 * @returns React component
 */
function StoreProvider({ children }: { children: React.ReactNode }) {
  return <Provider store={store}>{children}</Provider>;
}

export default StoreProvider;
