import {
  TabPanelPassThroughMethodOptions,
  TabPanelPassThroughOptions,
  TabViewPassThroughOptions,
} from 'primereact/tabview';
import { classNames } from 'primereact/utils';

export const TabViewPt: TabViewPassThroughOptions = {
  nav: 'flex flex-row border-b border-gray-200',
};

export const TabPanelPt: TabPanelPassThroughOptions = {
  header: (state: TabPanelPassThroughMethodOptions) => {
    return classNames(
      'px-1rem py-0.5rem hover:bg-gray-100 cursor-pointer transition-all min-w-[6rem] text-center',
      {
        'border-b-2 border-primary-500': (state.context as any)?.active,
      }
    );
  },

  content: (state: TabPanelPassThroughMethodOptions) => {
    return classNames('p-1rem');
  },
};
