import {
  PanelPassThroughMethodOptions,
  PanelPassThroughOptions,
  PanelPassThroughTransitionType,
} from 'primereact/panel';
import { classNames } from 'primereact/utils';

const TRANSITIONS = {
  toggleable: {
    enterFromClass: 'max-h-0',
    enterActiveClass: 'overflow-hidden transition-all duration-500 ease-in-out',
    enterToClass: 'max-h-40	',
    leaveFromClass: 'max-h-40',
    leaveActiveClass: 'overflow-hidden transition-all duration-500 ease-in',
    leaveToClass: 'max-h-0',
    addEndListener: () => {},
  } as PanelPassThroughTransitionType,
};

export const PanelPt: PanelPassThroughOptions = {
  header: ({ props, state }: PanelPassThroughMethodOptions) => ({
    className: classNames(
      'flex items-center justify-between transition-all duration-300', // flex and alignments
      'border border-gray-300 bg-white text-gray-700 rounded-tl-lg rounded-tr-lg', // borders and colors
      'dark:bg-gray-900 dark:border-blue-900/40 dark:text-white/80', // Dark mode
      { 'p-5': !props.toggleable, 'py-3 px-5': props.toggleable } // condition
    ),
  }),
  title: 'leading-none font-bold',
  toggler: {
    className: classNames(
      'inline-flex items-center justify-center overflow-hidden relative no-underline', // alignments
      'w-8 h-8 text-gray-600 border-0 bg-transparent rounded-full transition duration-200 ease-in-out', // widths, borders, and transitions
      'hover:text-gray-900 hover:border-transparent hover:bg-gray-200 dark:hover:text-white/80 dark:hover:bg-gray-800/80 dark:focus:shadow-[inset_0_0_0_0.2rem_rgba(147,197,253,0.5)]', // hover
      'focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)]' // focus
    ),
  },
  togglerIcon: 'inline-block',
  content: {
    className: classNames(
      'p-5 border border-gray-300 bg-white text-gray-700 border-t-0 last:rounded-br-lg last:rounded-bl-lg',
      'dark:bg-gray-900 dark:border-blue-900/40 dark:text-white/80' // Dark mode
    ),
  },
  transition: TRANSITIONS.toggleable,
};
